////////////////////////////////////////////////////////////////////////
////                          IMPORT APIS                           ////
////////////////////////////////////////////////////////////////////////

// Helpers
// import * as API from '../utils/API';

////////////////////////////////////////////////////////////////////////
////                           MAIN REDUCER                         ////
////////////////////////////////////////////////////////////////////////

const MODULE_NAME = `MAINREDUCER`;

// LOGIN STATE
const SET_AUTH_USER = `${MODULE_NAME}/SET_AUTH_USER`;
const SET_SESSION_TOKEN = `${MODULE_NAME}/SET_SESSION_TOKEN`;
const SET_LOGIN_EMAIL = `${MODULE_NAME}/SET_LOGIN_EMAIL`;
const SET_LOGIN_DESTINATION = `${MODULE_NAME}/SET_LOGIN_DESTINATION`;
const CLEAR_STATE_AFTER_LOGOUT = `${MODULE_NAME}/CLEAR_STATE_AFTER_LOGOUT`;

// ERROR
const SET_ERROR = `${MODULE_NAME}/SET_ERROR`;
const SET_AUTH_ERROR = `${MODULE_NAME}/SET_AUTH_ERROR`;

// UI STATE
const SET_LOADING = `${MODULE_NAME}/SET_LOADING`;
const SET_THEME_COLOR = `${MODULE_NAME}/SET_THEME_COLOR`;

// Initial State
const initialState = {
  authUser: null,
  email: null
};

/**
 * Reducer Function
 *
 * @param {*} state State object
 * @param {*} action Action contains state change relevant parameters.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_STATE_AFTER_LOGOUT:
      // we do not need to show loading as we have loaded everything already after the logout
      return { ...initialState, loading: false };
    case SET_AUTH_USER:
      return {
        ...state,
        authUser: action.authUser,
      };
    case SET_LOGIN_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_LOGIN_DESTINATION:
      return {
        ...state,
        loginDestination: action.destination,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        authError: null,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        error: action.authError,
        authError: action.authError,
      };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////
////                          ACTION CREATORS                       ////
////////////////////////////////////////////////////////////////////////

export function setThemeColor(themeColor) {
  return {
    type: SET_THEME_COLOR,
    themeColor,
  };
}

export function setAuthUser(authUser) {
  return {
    type: SET_AUTH_USER,
    authUser,
  };
}

export function setSessionToken(sessionToken) {
  return {
    type: SET_SESSION_TOKEN,
    sessionToken,
  };
}

export function setLoginEmail(email) {
  return {
    type: SET_LOGIN_EMAIL,
    email,
  };
}

export function setLoginDestination(destination) {
  return {
    type: SET_LOGIN_DESTINATION,
    destination,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setAuthError(authError) {
  return {
    type: SET_AUTH_ERROR,
    authError,
  };
}

export function clearStateAfterLogout() {
  return {
    type: CLEAR_STATE_AFTER_LOGOUT,
  };
}