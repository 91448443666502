////////////////////////////////////////////////////////////////////////
////                          REACT / REDUX                         ////
////////////////////////////////////////////////////////////////////////

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore, { history } from './utils/configureStore';
import reportWebVitals from './reportWebVitals'
import firebase from './utils/firebase'

////////////////////////////////////////////////////////////////////////
////                             ROUTING                            ////
////////////////////////////////////////////////////////////////////////

import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import Home from './App';

////////////////////////////////////////////////////////////////////////
////                             STYLES                             ////
////////////////////////////////////////////////////////////////////////

import './index.css';

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/' exact={true} component={Home} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
