import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

import config from './firebaseConfig.json';

firebase.initializeApp(config);

const auth = firebase.auth();
const storage = firebase.storage();

export default { auth, storage };
